import React from "react"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

const Alert = styled.div`
	${tw`flex bg-blue-200 border-l-8 rounded-md border-blue-600 my-8 p-4`}
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${tw`m-4 text-blue-600 text-2xl md:text-4xl float-left`}
`

const Title = styled.h3`
	${tw`text-black`}
`

const Message = styled.p`
	${tw`text-black text-lg`}
`

const AffiliateAlert = () => {
	return (
		<Alert>
			<StyledFontAwesomeIcon icon={faInfoCircle} />
			<div>
				<Title>Disclosure</Title>
				<Message>I may earn a small commission for my endorsement, recommendation, testimonial, and/or link to any products or services from this website. Your purchase helps support my work in bringing you real information about the best game development and technology products. I am an independent blogger and the reviews are done based on my own opinions.</Message>
			</div>
		</Alert>
	)
}
	
export default AffiliateAlert