import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section/section"
import SectionSubscribe from "../components/section/section-subscribe"
import AffiliateAlert from "../components/article/affiliate-alert"
import WarningAlert from "../components/article/warning-alert"

const Wrapper = styled.div`
    ${tw`container flex flex-col items-center`}
`

const Container = styled.div`
    ${tw`max-w-4xl`}
`

const SectionImageLeft = styled.div`
    ${tw`hidden md:block md:w-7/12 float-left m-2`}
`

const SectionImageRight = styled.div`
    ${tw`hidden md:block md:w-7/12 float-right m-2`}
`

const Attribution = styled.div`
    ${tw`text-white text-center`}
`

const CenterVideo = styled.div`
    ${tw`flex items-center justify-center`}
`

const BoldLink = styled.span`
    ${tw`font-semibold`}
`

const AboutPage = ({ data, location }) => {
    return(
        <Layout>
            <SEO
                title="About"
                pathname={location.pathname}
            />
            <Hero title="About" description="Learn more about Pocket Shutterbug" image={data.hero.childImageSharp.fluid} />
            <Section>
                <Wrapper>
                    <Container>
                        <SectionImageRight>
                            <Img fluid={data.photo.childImageSharp.fluid} alt="iPhoto Autumn Leaves Photo" />
                            <figcaption><strong>Image by Free-Photos from Pixabay</strong>&emsp;(Source: Pixabay)</figcaption>
                        </SectionImageRight>
                        <p>Welcome to <strong>Pocket Shutterbug</strong>, bringing you a selection of the best news, tips, tutorials, and reviews to help you become a better iPhone photographer and videographer.</p>
                        <p>Mobile phone camera technology is reaching a point where they can be used to shoot exceptionally high quality photos and videos. With the advent of advanced computational photography, techniques that were once only possible with expensive DSLR and mirrorless cameras are now possible with the phone that you carry in your pocket.</p>
                        <WarningAlert title="Computational Photography" children="Defines a type of photography where the image capture process uses sophisticated computing techniques to maximise image quality. For example, in Portrait Mode on an iPhone, it attempts to capture scenes that would typically be lit by professional studio lighting. In many cases, the iPhone takes multiple images and processes them to combine to produce a single image. This has only become possible with the increasing processing power of the more recent iPhones." />
                        <p>To read more about computational photography, I recommend starting with the <a href="https://en.wikipedia.org/wiki/Computational_photography" target="_blank" rel="noopener noreferrer"><BoldLink>computational photography Wikipedia page</BoldLink></a>.</p>
                        <p>I have recently upgraded to the iPhone 12 Pro Max, and whilst I am still learning how to use the new camera system, it already amazes me the quality of the shots that have come straight out of the phone. Whilst I do enjoy editing photos in Apple Photos, Affinity Photo or Pixelmator on either my iPad or Mac, it is refreshing just selecting and sharing the best photos with minimal editing. The addition of 10-bit Dolby Vision video turns the iPhone into a very capable video camera as well. The footage I have taken so far already looks better than my Fujifilm camera.</p>
                        <p>As I learn more about the new lens and sensors in the iPhone 12, I will be sharing my journey and experiences. Whilst I still love to shoot with my Fujifilm camera, I do not always carry it with me. Having such a capable camera in my pocket is a testament to the advances in smartphone camera technology.</p>
                        <AffiliateAlert/>
                    </Container>
                </Wrapper>
            </Section>
            <Section>
                <Wrapper>
                    <Container>
                        <p>If you would like to contact us, please <a href="mailto:hello@pocketshutterbug.com?subject=Hi%20Pocket%20Shutterbug!">send us an email.</a></p>
                    </Container>
                </Wrapper>
            </Section>
            <Section>
                <Attribution>
                    <div>Pocket Shutterbug is proudly hosted on <a href="https://www.netlify.com" target="_blank" rel="noopener noreferrer"><BoldLink>Netlify</BoldLink></a>.</div>
                    <div>Vector graphics by <a href="https://icons8.com/" target="_blank" rel="noopener noreferrer"><BoldLink>Icons8</BoldLink></a>.</div>
                    <div>Images by <a href="https://pixabay.com/" target="_blank" rel="noopener noreferrer"><BoldLink>Pixabay</BoldLink></a>.</div>
                </Attribution>
            </Section>
        </Layout>
    )
}

export default AboutPage

export const query=graphql`
    query {
        hero: file(relativePath: { eq: "mirage-welcome_400.png" }) {
            childImageSharp {
                fluid(maxWidth: 400 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        photo: file(relativePath: { eq: "phone-1031194_600.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 600 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`
